<template>
  <div>
    <template v-if="props.row['ram4mAssessImprIds'] && props.row['stepFlagNames']">
      <q-chip
        v-for="(item, index) in $comm.fmImprTagItems(props.row)"
        :key="index"
        :color="item.color"
        :clickable="true"
        text-color="white"
        style="margin-bottom:4px !important;"
        @click="openImprPop(item, props.row)">
        {{item.title}}
        <c-assess-tooltip 
          isVendor
          :ibmTooltip="item.ibmFmTooltip"
        />
      </q-chip>
    </template>
    <template v-else>
    </template>
    <div v-if="editable && Boolean(props.row.ram4mAssessScenarioId) && props.row.editFlag !== 'C' && inputEditable">
      <q-btn-group outline class="ColumInnerBtnGroup">
        <q-btn
          icon="add"
          color="red-6"
          text-color="white"
          class="ColumInnerBtn"
          align="center"
          @click.prevent="innerBtnClicked(props)">
          <q-tooltip>
            <!-- 개선등록 -->
            {{setLabel('LBL0001189')}}
          </q-tooltip>
        </q-btn>
      </q-btn-group>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mixinConvert from '@/js/mixin-template-convert'
export default {
  name: 'fmTableImpr',
  mixins: [mixinConvert],
  props: {
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
    inputEditable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      requestImprRow: null,
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(props) {
      this.requestImprRow = props.row
      let requestContents = props.row.jobName + ' / '
        + props.row.ram4mRiskHazardClassName + ' / '
        + props.row.riskHazardName;
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: props.row.ram4mAssessScenarioId,
        ibmTaskTypeCd: 'ITT0000023',
        ibmTaskUnderTypeCd: 'ITTU000030',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.ram4mAssessImprIds ? this.requestImprRow.ram4mAssessImprIds : '';
          if (sData.indexOf(item.ram4mAssessImprId) === -1) {
            this.requestImprRow.ram4mAssessImprIds = this.requestImprRow.ram4mAssessImprIds ? 
              this.requestImprRow.ram4mAssessImprIds + ',' + item.ram4mAssessImprId : item.ram4mAssessImprId
            this.requestImprRow.stepFlagNames = this.requestImprRow.stepFlagNames ? 
              this.requestImprRow.stepFlagNames + ',' + item.stepFlagName : item.stepFlagName
            this.requestImprRow.ibmFmTooltip = this.requestImprRow.ibmFmTooltip ? 
              this.requestImprRow.ibmFmTooltip + ',' + item.ibmFmTooltip : item.ibmFmTooltip
          }
        })
        this.$emit('imprChange')
        // this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        // this.research();
      }
    },
    openImprPop(data, row) {
      this.requestImprRow = row
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        ram4mAssessImprId: data.ram4mAssessImprId,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduceDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.ram4mAssessImprIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.stepFlagNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.ram4mAssessImprId);
            splitStep[idIndex] = result.col1.stepFlagName;
            this.requestImprRow.stepFlagNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.ram4mAssessImprIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.stepFlagNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.ram4mAssessImprId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'ram4mAssessImprIds', splitId.toString())
            this.$set(this.requestImprRow, 'stepFlagNames', splitStep.toString())
          }
        }
        this.$emit('imprChange')
        // this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        // this.research();
      }
    },
  }
};
</script>
